import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { useState } from "react";
import { Loading } from "../../Components/Loading";
import Container from "../../Components/Container";
import CustomTable from "../../Components/CustomTable";

export const Reservations = () => {
  const [isLoading, setIsLoading] = useState(false);

  const reservations = useFirestoreCollection("reservations", setIsLoading);

  return (
    <section className="users-list centered column">
      <Container w="100%" m="2rem 0 0 0" center>
        <h3>Reservations</h3>
      </Container>

      <CustomTable
        columns={[
          { id: "id", label: "ID", status: "hidden" },
          { id: "timestamp", label: "Date" },
          { id: "name", label: "Name" },
          { id: "surname", label: "Surname" },
          { id: "phone", label: "Phone" },
          { id: "tableMates", label: "Table Mates" },
          { id: "table_type", label: "Table" },
        ]}
        rows={reservations}
      />

      {isLoading && <Loading />}
    </section>
  );
};
