import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { deleteFile, deleteDocument, updateDocument } from "../../fb/FirebaseFunctions";
import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { generateRandomString } from "../../Utils/RandomUtils";
import { generateQRCode } from "../../Utils/QrCodeUtils";
import { setDocument } from "../../fb/FirebaseFunctions";
import { Loading } from "../../Components/Loading";
import { useNavigate } from "react-router-dom";
import "./Admin.css";
import Container from "../../Components/Container";
import Button from "../../Components/Button";
import CustomTable from "../../Components/CustomTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { sendPdfTicket } from "../../Components/TicketComponent";

export const Payments = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(0);

  const payments = useFirestoreCollection(
    "payments",
    setIsLoading,
    reloadTrigger
  );

  const handleReload = useCallback(() => {
    setReloadTrigger((prev) => prev + 1);
  }, []);

  const deletePayment = useCallback(async (user) => {
    setIsLoading(true);
    try {
      deleteFile(user.file_ref, setIsLoading);
      deleteDocument("payments", user.id, setIsLoading);

      toast.success(`Payment is Deleted!`);
    } catch (error) {
      toast.error(`Error Deleting ${user.name} ${user.surname} Payment`);
    } finally {
      handleReload();
      setIsLoading(false);
    }
  }, []);

  const addUser = useCallback(async (user) => {
    setIsLoading(true);
    try {
      const code = generateRandomString(5);

      const QRcodeID = `${generateRandomString(3)}-${generateRandomString(3)}-${generateRandomString(3)}-${generateRandomString(4)}`;
      const qrCodeDataUrl = generateQRCode(QRcodeID);

      setDocument(
        user.event,
        user.email,
        {
          name: user.name,
          surname: user.surname,
          email: user.email,
          dob: user.dob,
          phone: user.phone,
          ticket: user.ticket_type,
          code: code,
          qr_code: QRcodeID,
          table_num: "",
        },
        setIsLoading
      );

      sendPdfTicket(
        `${user.name} ${user.surname}`,
        user.email,
        QRcodeID,
        user.ticket_type,
        setIsLoading
      );

      updateDocument('payments', user.id, {
        confirmed: true
      }, setIsLoading)

      toast.success(`${user.name} ${user.surname} is Added!`);
    } catch (error) {
      toast.error(`Error Adding ${user.name} ${user.surname}`);
    } finally {
      handleReload();
      setIsLoading(false);
    }
  }, []);

  return (
    <section className="users-list centered column">
      <Button
        variant="ordinary"
        m="1rem"
        pd="0.5rem"
        onClick={() => navigate("/" + process.env.REACT_APP_ADMIN_LINK)}
        sx={{ position: "absolute", top: "0", left: "0" }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </Button>

      <Container w="100%" m="2rem 0 0 0" center>
        <h3>Payments</h3>
      </Container>

      <CustomTable
        columns={[
          { id: "id", label: "ID", status: "hidden" },
          { id: "file_ref", label: "File", status: "hidden" },
          { id: "timestamp", label: "Date" },
          { id: "name", label: "Name" },
          { id: "surname", label: "Surname" },
          { id: "email", label: "Email" },
          { id: "event", label: "Event" },
          { id: "phone", label: "Phone" },
          { id: "dob", label: "DOB", status: "hidden" },
          { id: "ticket_type", label: "Ticket" },
          { id: "promo_code", label: "Promo Code" },
          { id: "receipt", label: "Receipt" },
        ]}
        status='confirmed'
        rows={payments}
        addRow={addUser}
        deleteRow={deletePayment}
        handleReload={handleReload}
      />

      {isLoading && <Loading />}
    </section>
  );
};
