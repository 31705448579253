import React, { useRef, useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Container from './Container';
import './Ticket.css'
import useEmail from '../hooks/useEmail';

export const TicketComponent = () => {

const qrCode = 'https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=https://fiestaeventgroup.az';

  return (
    <div className="ticket-container" style={{ border: 'solid lightgray 1px' }}>
      <div className="ticket-left-left">
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.3rem', color: 'black' }}>
          <div className="qr-placeholder-left">
            <img width="100%" src={qrCode} alt="QR Code" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.1rem', color: 'black' }}>
            <p style={{ fontSize: '0.5rem', fontWeight: 600 }}>CONTACT US</p>
            <p style={{ fontSize: '0.5rem', fontWeight: 600, color: '#3C5E96' }}>+994 10 713 7097</p>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'black' }}>
          <p style={{ fontSize: '0.4rem', fontWeight: 600, color: '#3C5E96' }}>JOLLY JOKER</p>
          <p style={{ fontSize: '0.4rem', fontWeight: 600 }}>ELEKTRA EVENT HALL</p>
        </div>
      </div>
      <div className="ticket-left">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img width="50rem" src="./images/logo/no-text.png" alt="Logo" />
        </div>
        <div className="qr-placeholder">
          <img width="100%" src={qrCode} alt="QR Code" />
        </div>
        <p style={{ fontSize: '0.7rem', fontWeight: 700, fontFamily: 'Montserrat, sans-serif', color: '#3C5E96', letterSpacing: '1px' }}>
          TICKET: <span style={{ color: '#FF3030' }}>PREMIUM</span>
        </p>
        <p style={{ fontSize: '0.7rem', fontWeight: 600, fontFamily: 'Montserrat, sans-serif', color: '#3C5E96', textAlign: 'center' }}>Kanan Gurbanli</p>
      </div>
      <div className="ticket-right">
        <div className="ticket-right-circles">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>

        </div>
        <div className="event-title">
          <h1 style={{ fontSize: '3.4rem', color: '#FF3030', fontFamily: 'Montserrat, sans-serif', fontWeight: 600, lineHeight: '3rem' }}>BLOODY</h1>
          <p style={{ fontSize: '2rem', color: 'white', fontFamily: 'Montserrat, sans-serif', fontWeight: 600, letterSpacing: '0.2rem', marginBottom: '2rem' }}>JACK</p>
        </div>
        <div className="event-details">
          <p style={{ fontSize: '0.7rem', fontFamily: 'Montserrat, sans-serif', fontWeight: 600, color: 'white' }}>27th October 2024</p>
          <p style={{ fontSize: '0.7rem', fontFamily: 'Montserrat, sans-serif', fontWeight: 600, color: 'white' }}>18:00</p>
          <p style={{ fontSize: '0.7rem', fontFamily: 'Montserrat, sans-serif', fontWeight: 600, color: 'white', marginTop: '1rem' }}>
            JOLLY JOKER <span style={{ color: '#00bf63' }}>- ELEKTRA EVENTS HALL</span>
          </p>
        </div>
        <div className="price">
          <p style={{ fontSize: '1rem', color: 'white', fontWeight: 600, textShadow: '0 0 10px #000'}}>100 AZN</p>
        </div>
      </div>
    </div>
  );
};


function compressPdfBlob(pdfBlob) {
    return new Promise((resolve, reject) => {
        // Here, we could use external compression libraries or services
        // For simplicity, we'll just pass the blob through in this example.
        resolve(pdfBlob);
    });
}


export const sendPdfTicket = (name, email, qrCodeID, ticketType, setIsLoading) => {
    const ticketPrice = ticketType == "STANDARD" ? "60" : ticketType == "PREMIUM" ? "100" : "130";
    const qrCode = `https://api.qrserver.com/v1/create-qr-code/?size=200x200&amp;data=${qrCodeID}`;
    const ticketElement = document.createElement('div'); // Create a container to render the ticket in
    ticketElement.innerHTML = `
        <div class="ticket-container" style="border: solid lightgray 1px">
          <div class="ticket-left-left">
            <div style="display: flex; flex-direction: row; gap: 0.3rem; color: black;">
              <div class="qr-placeholder-left"><img width="100%" src="https://api.qrserver.com/v1/create-qr-code/?size=200x200&amp;data=https://fiestaeventgroup.az" alt="QR Code" /></div>
              <div style="display: flex; flex-direction: column; gap: 0.1rem; color: black;">
                <p style="font-size: 0.5rem; font-weight: 600;">CONTACT US</p>
                <p style="font-size: 0.5rem; font-weight: 600; color: #3C5E96;">+994 10 713 7097</p>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center; color: black;">
              <p style="font-size: 0.4rem; font-weight: 600; color: #3C5E96;">JOLLY JOKER</p>
              <p style="font-size: 0.4rem; font-weight: 600;">ELEKTRA EVENT HALL</p>
            </div>
          </div>
          <div class="ticket-left">
            <div style="display: flex; justify-content: center;">
              <img width="50rem" src="./images/logo/no-text.png" alt="Logo" />
            </div>
            <div class="qr-placeholder"><img width="100%" src="${qrCode}" alt="QR Code" /></div>
            <p style="font-size: 0.7rem; font-weight: 700; font-family: 'Montserrat, sans-serif'; color: #3C5E96; letter-spacing: 1px;">TICKET: <span style="color: #FF3030;">${ticketType}</span></p>
            <p style="font-size: 0.7rem; font-weight: 600; font-family: 'Montserrat, sans-serif'; color: #3C5E96; text-align: center;">${name}</p>
          </div>
          <div class="ticket-right">
            <div class="ticket-right-circles">
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
                <div class="ticket-circle"></div>
            </div>
            <div class="event-title">
              <h1 style="font-size: 3.4rem; color: #FF3030; font-family: 'Montserrat, sans-serif'; font-weight: 600; line-height: 3rem;">BLOODY</h1>
              <p style="font-size: 2rem; color: white; font-family: 'Montserrat, sans-serif'; font-weight: 600; letter-spacing: 0.2rem; margin-bottom: 2rem">JACK</p>
            </div>
            <div class="event-details">
              <p style="font-size: 0.7rem; font-family: 'Montserrat, sans-serif'; font-weight: 600; color: white;">27th October 2024</p>
              <p style="font-size: 0.7rem; font-family: 'Montserrat, sans-serif'; font-weight: 600; color: white;">18:00</p>
              <p style="font-size: 0.7rem; font-family: 'Montserrat, sans-serif'; font-weight: 600; color: white; margin-top: 1rem;">JOLLY JOKER <span style="color: #00bf63;">- ELEKTRA EVENTS HALL</span></p>
            </div>
            <div class="price">
              <p style="font-size: 1rem; color: white; font-weight: 600; padding: 0 0.1rem;">${ticketPrice} AZN</p>
            </div>
          </div>
        </div>
    `;

    document.body.appendChild(ticketElement); // Temporarily add the ticket to the DOM for rendering

    html2canvas(ticketElement, { scale: 2, useCORS: true }).then((canvas) => {
        // Create a Blob from the canvas instead of DataURL to manage file size better
        canvas.toBlob(function (blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                const imgData = reader.result;
    
                // Create PDF with jsPDF
                const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'mm',
                    format: [84, 28]  // Custom page size in millimeters
                });
                const imgWidth = 180;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
                // Add image to the PDF with 'FAST' rendering to further compress
                pdf.addImage(imgData, 'PNG', -3, 0, imgWidth, imgHeight, undefined, 'FAST');
    
                const pdfBlob = pdf.output('blob');
    
                // Compress the PDF Blob
                compressPdfBlob(pdfBlob).then((compressedBlob) => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(compressedBlob);
                    fileReader.onloadend = function () {
                        const compressedBase64 = fileReader.result.split(',')[1];
    
                        // Send the compressed PDF via email
                        useEmail('template_y1l8h4a', {
                            user_email: email,
                            ticket: compressedBase64,
                        }, setIsLoading);
                    };
                });
            };
        }, 'image/jpeg', 0.8);  // Use JPEG format with moderate compression to reduce size without losing too much quality
    });

    document.body.removeChild(ticketElement);
    
};
