import "./Events.css";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Loading } from "../../Components/Loading";
import { toast } from "react-toastify";
import { auth, db } from "../../fb";
import { doc, setDoc } from "firebase/firestore";
import { EventPayment } from "../../forms/EventPayment";
import { TableReservation } from "../../forms/TableReservation";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../Components/Button";
import Container from "../../Components/Container";
import Skeleton from "@mui/material/Skeleton";
import Link from "../../Components/Link";

export const Events = () => {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();

  const passwordRef = useRef("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [school, setSchool] = useState("");
  const [section, setSection] = useState("");

  const [formType, setFormType] = useState("payment");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [paneVideo, setPaneVideo] = useState({
    visible: false,
    name: "",
    video: null,
    info: "",
    details: "",
  });

  const Event = (props) => {
    const imgLink = `https://drive.google.com/thumbnail?id=${props.imgSrc}&sz=w1000`;
    const [imageLoaded, setImageLoaded] = useState(false);
    return (
      <div className="event centered">
        <Button
          pd="0"
          onClick={() => {
            setPaneVideo({
              visible: true,
              name: props.eventName,
              video: <img src={imgLink} className="pane-img" />,
              info: props.info,
              collection: props.collection,
              payment: props.payment,
              paymentTicketOptions: props.paymentTicketOptions,
              reservation: props.reservation,
              reservationTicketOptions: props.reservationTicketOptions
            });
          }}
        >
          {!imageLoaded && (
            <Skeleton
              variant="rounded"
              width={310}
              height={180}
              sx={{ background: "rgb(120, 120, 120)" }}
              animation="wave"
            />
          )}
          <img
            src={imgLink}
            style={{ display: imageLoaded ? "block" : "none" }}
            onLoad={() => setImageLoaded(true)}
          />
        </Button>

        <div className="event-info">
          {!imageLoaded ? (
            <Container column gap="0.5rem">
              <Skeleton
                variant="rounded"
                width={100}
                height={20}
                sx={{ background: "rgb(120, 120, 120)" }}
                animation="wave"
              />
              <Skeleton
                variant="rounded"
                width={80}
                height={20}
                sx={{ background: "rgb(120, 120, 120)" }}
                animation="wave"
              />
            </Container>
          ) : (
            <>
              <h5>{props.eventName}</h5>
              <p>{props.eventType}</p>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <section className="events centered">
      <SlidingPane
        overlayClassName="loading-overlay"
        isOpen={paneVideo.visible}
        onRequestClose={() => {
          setPaneVideo({ visible: false });
        }}
      >
        {paneVideo.video}

        <Container w="90%" m="1rem 0 2rem 0" center column gap="1rem">
          <h3>{paneVideo.name}</h3>
          {paneVideo.info}
        </Container>

        {(paneVideo.reservation || paneVideo.payment) && (
          <Container
            center
            w="80%"
            gap="1rem"
            m="0 0 2rem 0"
            className="row-column"
            sx={{ justifyContent: "space-evenly" }}
          >
            {paneVideo.reservation && (
              <Button
                variant="modern"
                shadow
                pd="2rem 0"
                w="100%"
                onClick={() => {
                  handleOpen();
                  setFormType("reservation");
                }}
                children={
                  <Container center w="100%" column color="rgb(0, 14, 44)">
                    <h4>
                      <strong>Reserve Table</strong>
                    </h4>
                  </Container>
                }
              />
            )}
            {paneVideo.payment && (
              <Button
                variant="modern"
                shadow
                pd="2rem 0"
                w="100%"
                onClick={() => {
                  handleOpen();
                  setFormType("payment");
                }}
                children={
                  <Container center w="100%" column color="rgb(0, 14, 44)">
                    <h4>
                      <strong>Upload Payment</strong>
                    </h4>
                  </Container>
                }
              />
            )}
          </Container>
        )}

        <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              minHeight: "100%",
              overflow: "scroll",
              position: "relative",
            }}
            className="centered"
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            >
              <CloseIcon />
            </IconButton>

            {formType === "payment" && (
              <EventPayment
                collection={paneVideo.collection? paneVideo.collection : ""}
                ticketOptions={paneVideo.paymentTicketOptions}
              />
            )}

            {formType === "reservation" && (
              <TableReservation
                ticketOptions={paneVideo.reservationTicketOptions}
              />
            )}
          </div>
        </Modal>

      </SlidingPane>

      <Container w="90%" column gap="1rem" m="2rem 0 0 0">
        <div className="event-container">
          {eventsEvents.map((event, index) => (
            <Event
              key={index}
              eventName={event.eventName}
              eventType={event.eventType}
              collection={event.collection}
              payment={event.payment}
              paymentTicketOptions={event.paymentTicketOptions}
              reservation={event.reservation}
              reservationTicketOptions={event.reservationTicketOptions}
              imgSrc={event.imgSrc}
              info={
                i18n.language === "en"
                  ? event.info
                  : i18n.language === "ru"
                  ? event.infoRu
                  : i18n.language === "az"
                  ? event.infoAz 
                  : ''
              }
              videoLink={event.videoLink}
            />
          ))}
        </div>
      </Container>
      

      <Container w="100%" center m="3rem 0">
        <h1>
          {t("events.more")}
          <br />
          {t("events.dont-miss")}
        </h1>
      </Container>

      {isLoading && <Loading />}
    </section>
  );
};

const eventsEvents = [
  // {
  //   eventName: (
  //     <span>
  //       <span style={{ textDecoration: "line-through" }}>Black</span> Bloody
  //       Jack
  //     </span>
  //   ),
  //   eventType: "Theme Party",
  //   imgSrc: "1EIh-kz4ybk4pe6YnwCEMRPy90HG0uJka",
  //   info: (
  //       <p>Halloween is creeping up, and we’re thrilled to invite you to one of our most spine-chilling events of the year.
  //       <br/>October 27th is your night to enter our haunted Las Vegas casino at Jolly Joker (Elektra Events Hall), where glamour meets the ghostly in a spectacular Halloween celebration!
  //       <br/>
  //       <br/>🎃 <strong>EVENT DETAILS</strong> 🎃
  //       <br/>🗓️ <strong>Date & Time:</strong> 27 October, 18:00
  //       <br/>📍 <strong>Location:</strong> Jolly Joker (Elektra Events Hall )
  //       <br/>🎟️ <strong>Ticket Types:</strong> Standard Zone, Premium Zone, VIP Zone
  //       <br/>
  //       <br/><strong>Standard Zone - 60 AZN</strong>
  //       <br/>&nbsp;• Standing Position (Separated Zone)
  //       <br/>&nbsp;• Access to the standard zone <strong>ONLY</strong>
  //       <br/>&nbsp;• Access to the Buffet
  //       <br/>&nbsp;• Special Led Bracelet
  //       <br/>&nbsp;• Opportunity to participate in a poll
  //       <br/>&nbsp;• Take special memories in Photobooth
  //       <br/>&nbsp;• Face art and special 2 Interactive Games
  //       <br/>&nbsp;• Special DJ AX Music Ambiance and Special Show Program
  //       <br/>&nbsp;• Special Dance Performance by MadHuskies
  //       <br/>
  //       <br/><strong>Premium Zone - 100 AZN</strong>
  //       <br/>&nbsp;• Standard ticket privileges
  //       <br/>&nbsp;• Seating Position (1st Floor)
  //       <br/>&nbsp;• Access to the premium zones
  //       <br/>&nbsp;• Special menu of food & beverage
  //       <br/>&nbsp;• 2 kind of Alcoholic Drinks;
  //       <br/>&nbsp;• Additional 3 Interactive Games
  //       <br/>&nbsp;• Special Contest of the best costume of the event
  //       <br/>&nbsp;• Special PhotoZone
  //       <br/>
  //       <br/><strong>VIP Zone - 130 AZN</strong>
  //       <br/>&nbsp;• Standard and Premium ticket privileges
  //       <br/>&nbsp;• Seating Position (2nd floor)
  //       <br/>&nbsp;• VIP <strong>Parking Spot</strong>
  //       <br/>&nbsp;• Special <strong>Separate</strong> Entrance
  //       <br/>&nbsp;• Access to VIP zone
  //       <br/>&nbsp;• 3 kinds of Alcoholic Drinks
  //       <br/>&nbsp;• Additional Private Teams Games
  //       <br/>&nbsp;• Special Prize Auction of the Day (Opportunity to win something special)
  //       <br/>
  //       <br/><strong>For More Info and Tickets:</strong>
  //       <br/>📞 +994107137007
  //       <br/>📨 fiestagroup@ada.edu.az
  //       <br/>
  //       <br/>We encourage you to unleash your creativity and come dressed in your most glamorous yet ghoulish Halloween attire!
  //       <br/>🕸 Don’t miss out on a thrilling evening of high stakes, high spirits, and hauntingly good times. This is your chance to celebrate Halloween in style—with a perfect mix of spooky sophistication and casino fun! 🎰💀
  //       </p>
  //   ),
  //   collection: "Black_Bloody_Jack",
  //   payment: true,
  //   paymentTicketOptions: [
  //       { value: "STANDARD", label: "Standard Zone - 60 AZN" },
  //       { value: "PREMIUM", label: "Premium Zone - 100 AZN" },
  //       { value: "VIP", label: "VIP Zone - 130 AZN" },
  //   ],
  //   reservation: false,
  //   reservationTicketOptions: [
  //       { value: "STANDARD", label: "Standard Zone - 60 AZN" },
  //       { value: "PREMIUM", label: "Premium Zone - 100 AZN" },
  //       { value: "VIP", label: "VIP Zone - 130 AZN" },
  //   ],
  // },
  {
    eventName: <span>Preparations for COP29</span>,
    eventType: "Fiesta Talks",
    imgSrc: "1ure75_REM-q0695GzQssna-3MnWBsSE6",
    info: (
      <p>
        This time, we’re hosting Trivandi, a globally known company visiting Azerbaijan for the very first time! Are you excited to meet the experts behind some of the world’s most unforgettable events? Trivandi is currently assisting with organizing the COP Event right here in Baku, making this an unforgettable opportunity!
        <br />
        <br /><strong>EVENT DETAILS</strong>
        <br />🗓️ <strong>Date & Time:</strong> 31 October, 17:00
        <br />📍 <strong>Venue:</strong> ADA University, Majlis Room (B building)
        <br />
        <br />Our esteemed speakers, Peter Docwra (Director of Operations & Delivery Advisory) and Javid Mammadli (Senior Manager, Operations & Delivery Advisory), will share their expertise on large-scale event planning and operations, offering interesting insights into the challenges, strategies, and skills required to bring major events like COP to life.
        <br />
        <br />This is a unique chance to connect with top event professionals. Don’t miss this exciting opportunity to learn directly from the best!
        <br />
        <br />Secure your spot now from the following link: <Link variant="footer" href="https://forms.gle/UWB17vE9JCJ8tPss9">Register Here</Link>
      </p>
    ),
    payment: false,
    reservation: false,
  },
];
