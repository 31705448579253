import './Remember.css';
import { useTranslation } from "react-i18next";

export const Remember = () => {
    const {t} = useTranslation("common");
    return(
        <section className='remember'>

                <div className='photo-container centered'>
                    <img src='/images/remember-photo.jpg' className='photo' alt='Photo1'/>
                    <div className='photo-overlay'></div>
                </div>

                <div className='container centered'>

                    <div style={{flex: 1}}></div>

                    <div className='text-container centered' style={{flex: 1}}>
                        <div className='text'>
                            <h1>{t('home.remember.header')}</h1>
                            <br/>
                            <h5>{t('home.remember.body1-1')} <strong style={{color: 'rgb(47, 182, 253)'}}>FIESTA Event Group!</strong> {t('home.remember.body1-2')}</h5>
                            <br/>
                            <h5>{t('home.remember.body2-1')} <strong style={{color: 'rgb(47, 182, 253)'}}>FIESTA Event Group</strong>{t('home.remember.body2-2')}</h5>
                        </div>
                    </div>

                </div>
                
        </section>
    );
}