import { useState, useMemo } from "react";
import { Loading } from "../../Components/Loading";
import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Star from "@mui/icons-material/Star";
import "./Admin.css";
import Container from "../../Components/Container";
import Button from "../../Components/Button";
import CustomTable from "../../Components/CustomTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import Select from "../../Components/Select";

export const Feedbacks = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState("");

  // Static collections
  const collections = [
    { label: "Select the collection", value: "" },
    { label: "Users", value: "users" },
    { label: "Feedbacks", value: "feedbacks" },
    { label: "Job Applications", value: "jobApplications" },
    { label: "Contact Request", value: "contactRequest" },
  ];

  // Fetch data only if a collection is selected
  const data = useFirestoreCollection(selectedCollection || "", setIsLoading);

  // Calculate average rating for feedbacks
  const aveRating = useMemo(() => {
    if (selectedCollection === "feedbacks" && data.length > 0) {
      let allRatings = 0;
      data.forEach((feedback) => (allRatings += feedback.rating));
      return Math.round((allRatings / data.length) * 10) / 10;
    }
    return null;
  }, [data, selectedCollection]);

  const handleCollectionChange = (event) => {
    setSelectedCollection(event.target.value);
  };

  // Define dynamic columns based on the selected collection
  const columns = useMemo(() => {
    if (selectedCollection === "users") {
      return [
        { id: "name", label: "Name" },
        { id: "surname", label: "Surname" },
        { id: "email", label: "Email" },
        { id: "dob", label: "DOB" },
        { id: "phone", label: "Phone" },
      ];
    } else if (selectedCollection === "feedbacks") {
      return [
        { id: "dateTime", label: "Date" },
        { id: "event", label: "Event" },
        { id: "feedback", label: "Feedback" },
        { id: "rating", label: "Rating" },
        { id: "sender", label: "Sender" },
      ];
    } else if (selectedCollection === "jobApplications") {
      return [
        { id: "timestamp", label: "Date" },
        { id: "name", label: "Name" },
        { id: "surname", label: "Surname" },
        { id: "dob", label: "DOB" },
        { id: "email", label: "Email" },
        { id: "phone", label: "Phone" },
        { id: "position", label: "Position" },
        { id: "message", label: "Message" },
        { id: "cv", label: "CV" },
      ];
    } else if (selectedCollection === "contactRequest") {
      return [
        { id: "timestamp", label: "Date" },
        { id: "name", label: "Name" },
        { id: "surname", label: "Surname" },
        { id: "email", label: "Email" },
        { id: "dob", label: "DOB" },
        { id: "phone", label: "Phone" },
        { id: "message", label: "Message" },
      ];
    }
    return [];
  }, [selectedCollection]);

  return (
    <section className="admin-panel centered column">
      <Button
        variant="ordinary"
        m="1rem"
        pd="0.5rem"
        onClick={() => navigate("/" + process.env.REACT_APP_ADMIN_LINK)}
        sx={{ position: "absolute", top: "0", left: "0" }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </Button>

      {/* Show average rating for feedbacks */}
      {selectedCollection === "feedbacks" && aveRating && (
        <Container
          gap="0.2rem"
          sx={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}
        >
          <h5>
            <Rating
              style={{ fontSize: "1.5rem" }}
              value={aveRating}
              readOnly
              precision={0.1}
              emptyIcon={<Star fontSize="inherit" sx={{ color: "white" }} />}
            />
          </h5>
        </Container>
      )}

      <Container w="100%" m="2rem 0 0 0" center>
        <Select
          onChange={handleCollectionChange}
          value={selectedCollection}
          options={collections}
          fontSize="medium"
          m="1rem"
        />
      </Container>


      {/* Display table if a valid collection is selected */}
      {selectedCollection && <CustomTable columns={columns} rows={data} />}

      {/* Loading indicator */}
      {isLoading && <Loading />}
    </section>
  );
};
