// React and React Hooks
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../Components/Loading";

// Third-party Libraries
import useEmail from "../../hooks/useEmail";
import { toast } from "react-toastify";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CodeVerification from "../../Components/CodeVerification";
import PasswordStrengthBar from "react-password-strength-bar";

// Firebase
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../fb";
import { setDocument } from "../../fb/FirebaseFunctions";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../fb";

// Custom Hooks
import useForm from "../../hooks/useForm";

// CSS
import "./Auth.css";

// Material UI Components and Icons
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Button from "../../Components/Button";
import Container from "../../Components/Container";

// Material UI Icons
import AccountCircle from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// IMaskInput
import { IMaskInput } from "react-imask";

import CountryCodeSelector, {
  countryCodes,
} from "../../Components/CountryCodeSelector";

const validateInput = (formData, phoneMask, passwordScore, t) => {
  const { name, surname, dob, phone, email, password, termsAccepted } =
    formData;

  if (
    /\d/.test(name) ||
    /[^a-zA-Z0-9\s]/.test(name) ||
    name.split(' ').length > 1 ||
    name.length < 2
  ) {
    toast.error(t("error.invalidName"));
    return false;
  }
  if (
    /\d/.test(surname) ||
    /[^a-zA-Z0-9\s]/.test(surname) ||
    surname.split(' ').length > 1 ||
    surname.length < 2
  ) {
    toast.error(t("error.invalidSurname"));
    return false;
  }
  const dobDay = dob.substring(0, 2);
  const dobMonth = dob.substring(3, 5);
  const dobYear = dob.substring(6);
  const currentYear = new Date().getFullYear();
  if (
    dobDay > 31 ||
    dobMonth > 12 ||
    dobYear <= 1900 ||
    dobYear >= currentYear
  ) {
    toast.error(t("error.invalidDob"));
    return false;
  }

  const cleanedPhone = phone.replace(/\D/g, "");
  const cleanedMask = phoneMask.replace(/\D/g, "");
  if (cleanedPhone.length !== cleanedMask.length) {
    toast.error(t("error.invalidPhone"));
    return false;
  }

  const emailRegex = /\S+@\S+\.\S+/;
  if (!emailRegex.test(email)) {
    toast.error(t("error.invalid-email"));
    return false;
  }
  if (passwordScore < 3) {
    toast.error(t("error.passwordNotStrong"));
    return false;
  }

  return true;
};

const PhoneMask = React.forwardRef(function PhoneMask(props, ref) {
  const { onChange, mask, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={mask}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const DobMask = React.forwardRef(function DobMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="##/##/####"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const generateVerificationCode = () => {
  return Math.floor(100000 + Math.random() * 900000).toString();
};

export const Register = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const initialState = {
    name: "",
    surname: "",
    dob: "",
    phone: "",
    countryCode: "+994",
    email: "",
    password: "",
    termsAccepted: true,
  };

  const [formData, handleChange, updateFormData] = useForm(initialState);
  const [phoneMask, setPhoneMask] = useState("00 000 00 00");
  const [loginPasswordType, setLoginPasswordType] = useState("password");
  const [passwordScore, setPasswordScore] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const [enteredCode, setEnteredCode] = useState("");

  const handleCodeChange = (code) => {
    setEnteredCode(code);
  };

  const sendVerificationEmail = useCallback(() => {
    const code = generateVerificationCode();
    setGeneratedCode(code);

    const templateParams = {
      subject: "Email Verification",
      user_email: formData.email.toLowerCase(),
      message: `Dear ${formData.name.charAt(0).toUpperCase() + formData.name.slice(1).toLowerCase()} ${formData.surname.charAt(0).toUpperCase() + formData.surname.slice(1).toLowerCase()},
      
                Please use the following code to verify your account.
                Your verification code is: ${code}.

                Best regards,
                Fiesta Event Team`,

      from_name: "Fiesta Event Team",
      from_email: 'fiesta.helpcenter.az@gmail.com',
      reply_to: 'fiesta.helpcenter.az@gmail.com'
    };

    useEmail("template_qs86jrb", templateParams, setIsLoading, false);

    toast.success(t("verification.success"));
  }, [formData.email.toLowerCase()]);

  const sendWelcomeEmail = useCallback(() => {

    const templateParams = {
      subject: "Welcome to Fiesta Family!",
      user_email: formData.email.toLowerCase(),
      message: `Dear ${formData.name.charAt(0).toUpperCase() + formData.name.slice(1).toLowerCase()} ${formData.surname.charAt(0).toUpperCase() + formData.surname.slice(1).toLowerCase()},

                Thank you for registering with Fiesta Events. 

                We would like to inform you that to purchase and get a ticket, you can approach our special stands (points) or contact us at fiestagroup@ada.edu.az or +994107137007. 

                ATTENTION: In order to receive your ticket, you must make payment before the deadline (see email for updated version). 

                To pay in installments, please contact fiestagroup@ada.edu.az.

                Get the best experience with the Fiesta Family!`,

      from_name: "Fiesta Event Team",
      from_email: 'fiesta.helpcenter.az@gmail.com',
      reply_to: 'fiesta.helpcenter.az@gmail.com'
    };

    useEmail("template_qs86jrb", templateParams, setIsLoading, false);
  }, [formData]);

  const handleNext = async () => {
    try {
      setIsLoading(true);
      if (activeStep === 0) {
        if (!validateInput(formData, phoneMask, passwordScore, t)) return;

        const q = query(collection(db, 'users'), where("email", "==", formData.email.toLowerCase()));
        try {
          const querySnapshot = await getDocs(q);
      
          if (!querySnapshot.empty) {
            toast.error(t('error.emailUsed'));
            return;
          }
        } catch (error) {
          console.error("Error fetching documents: ", error);
        }

        setActiveStep(1);
        sendVerificationEmail();
      } else if (activeStep === 1) {
        if (enteredCode !== generatedCode) {
          toast.error(t("verification.invalidCode"));
          return;
        }

        await createUserWithEmailAndPassword(auth, formData.email.toLowerCase(), formData.password);

        await setDocument("users", formData.email.toLowerCase(), {
            name: formData.name.charAt(0).toUpperCase() + formData.name.slice(1).toLowerCase(),
            surname: formData.surname.charAt(0).toUpperCase() + formData.surname.slice(1).toLowerCase(),
            dob: formData.dob,
            phone: `(${formData.countryCode})${formData.phone}`,
            email: formData.email.toLowerCase(),
          },
          setIsLoading
        );

        sendWelcomeEmail();

        setActiveStep(2);
      } else {
        navigate("/login");
      }
    } catch (error) {
      toast.error(t("toast.error"));
    } finally {
      setIsLoading(false);
    }
  };
  const togglePasswordVisibility = () => {
    setLoginPasswordType(
      loginPasswordType === "password" ? "text" : "password"
    );
  };

  const handleCountryCodeChange = (event) => {
    const selectedCode = event.target.value;
    const selectedCountry = countryCodes.find(
      (country) => country.code === selectedCode
    );
    if (selectedCountry) {
      setPhoneMask(selectedCountry.mask);
      updateFormData({
        countryCode: selectedCode,
        phone: "",
      });
    }
  };

  return (
    <section className="auth flex column centered">
      <form className="centered column">
        <Container
          color="black"
          w="100%"
          center
          pd="0 0 1rem 0"
          m="0 0 1rem 0"
          sx={{ borderBottom: "solid rgb(0, 44, 74) 0.1rem" }}
        >
          <Stepper alternativeLabel activeStep={activeStep}>
            <Step>
              <StepLabel>
                <p>{t("registration.step1")}</p>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                <p>{t("registration.step2")}</p>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                <p>{t("registration.step3")}</p>
              </StepLabel>
            </Step>
          </Stepper>
        </Container>
        <Container center column color="black" w="80%" gap="1rem">
          {/* <h4>{t("registration.header")}</h4> */}

          {activeStep == 0 && (
            <>
              <Container end w="100%">
                <TextField
                  name="name"
                  label={t("general.name")}
                  variant="standard"
                  value={formData.name.charAt(0).toUpperCase() + formData.name.slice(1).toLowerCase()}
                  onChange={handleChange}
                  style={{ marginRight: "0.5rem", width: "100%" }}
                />
                <TextField
                  name="surname"
                  label={t("general.surname")}
                  variant="standard"
                  value={formData.surname.charAt(0).toUpperCase() + formData.surname.slice(1).toLowerCase()}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                />
                <AccountCircle className="icon" />
              </Container>

              <Container end w="100%">
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <InputLabel htmlFor="dobMask">
                    {t("general.dob")}
                  </InputLabel>
                  <Input
                    value={formData.dob}
                    onChange={handleChange}
                    placeholder="DD/MM/YYYY"
                    name="dob"
                    id="dobMask"
                    inputComponent={DobMask}
                  />
                </FormControl>
                <CalendarMonthIcon className="icon" />
              </Container>

              <Container end w="100%">
                <CountryCodeSelector
                  value={formData.countryCode}
                  onChange={handleCountryCodeChange}
                  onMaskChange={setPhoneMask}
                />
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <InputLabel htmlFor="phoneNumber">
                    {t("general.phone")}
                  </InputLabel>
                  <Input
                    value={formData.phone}
                    onChange={handleChange}
                    name="phone"
                    id="phoneNumber"
                    inputComponent={PhoneMask}
                    inputProps={{
                      mask: phoneMask,
                    }}
                  />
                </FormControl>
                <PhoneIcon className="icon" />
              </Container>

              <Container end w="100%">
                <TextField
                  name="email"
                  label={t("general.email")}
                  variant="standard"
                  value={formData.email.toLowerCase()}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                />
                <EmailIcon className="icon" />
              </Container>

              <Container end w="100%">
                <TextField
                  type={loginPasswordType === "password" ? "password" : ""}
                  name="password"
                  label={t("general.password")}
                  variant="standard"
                  value={formData.password}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                />
                <Container
                  onClick={togglePasswordVisibility}
                  sx={{ cursor: "pointer" }}
                >
                  {loginPasswordType === "password" ? (
                    <VisibilityIcon className="icon" />
                  ) : (
                    <VisibilityOffIcon className="icon" />
                  )}
                </Container>
              </Container>
              <PasswordStrengthBar
                style={{ width: "100%" }}
                password={formData.password}
                scoreWords={['weak', 'weak', 'normal', 'good', 'strong']}
                onChangeScore={(score, feedback) => {
                  setPasswordScore(score);
                }}
              />

              {/* <Container color='black' w="100%">
                <FormGroup>
                  <FormControlLabel
                    id="terms-check"
                    control={<Checkbox sx={{ padding: "0rem 0.5rem" }} />}
                    label={t("registration.terms-accepted")}
                    name="termsAccepted"
                    onChange={handleChange}
                    checked={formData.termsAccepted}
                  />
                </FormGroup>
              </Container> */}

              {/* <Button type="submit" w="100%" pd="0.75rem">
                {t("registration.register-btn")}
              </Button>

              <Container center color='black' w="100%" gap="0.2rem">
                <strong>{t("registration.account")}</strong>
                <Link href="/login">{t("registration.login-btn")}</Link>
              </Container> */}
            </>
          )}

          {activeStep == 1 && (
            <Container column w="100%" color="black" gap="1.5rem">
              <CodeVerification onCodeChange={handleCodeChange} />
              <h5>
                {t("verification.instruction")} <strong>{formData.email.toLowerCase()}</strong>.
              </h5>
              <h5>{t("verification.emailReminder")}.</h5>
            </Container>
          )}

          {activeStep == 2 && (
            <Container column w="100%" color="black" gap="1.5rem">
              <h5>{t("registration.success")}</h5>
              <p>{t("registration.start")}</p>
            </Container>
          )}
        </Container>

        <Container
          w="100%"
          m="1.5rem 0 0 0"
          center
          sx={{ borderTop: "solid rgb(0, 44, 74) 0.1rem" }}
        >
          <Container w="80%" m="1rem 0 0 0" end>
            <Button
              onClick={handleNext}
              children={
                activeStep === 2 ? (
                  <p>{t("general.logInBtn")}</p>
                ) : (
                  <p>{t("registration.next")}</p>
                )
              }
            />
          </Container>
        </Container>
      </form>
      {isLoading && <Loading />}
    </section>
  );
};



// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import Container from '../../Components/Container';
// import Button from '../../Components/Button';
// import { Stepper, Step, StepLabel } from '@mui/material';

// export const Register = () => {
//     const { t } = useTranslation('common');
//     const [activeStep, setActiveStep] = useState(0);
//     const [selectedOptions, setSelectedOptions] = useState({
//         venue: null,
//         specialGuests: [],
//         eventType: null,
//         peopleSize: null,
//     });

//     const steps = ['Venue', 'Special Guests', 'Event Type', 'People Size'];

//     const handleSelection = (category, option) => {
//         setSelectedOptions(prev => {
//         if (category === 'specialGuests') {
//             return {
//             ...prev,
//             [category]: prev[category].includes(option)
//                 ? prev[category].filter(item => item !== option)
//                 : [...prev[category], option]
//             };
//         }
//         return { ...prev, [category]: option };
//         });
//     };

//     const handleNext = () => {
//         setActiveStep(prevStep => prevStep + 1);
//     };

//     const handleBack = () => {
//         setActiveStep(prevStep => prevStep - 1);
//     };

//     const renderOptions = (category, options) => {
//         return (
//           <div className="options-grid">
//             {options.map((option, index) => (
//               <div 
//                 key={index} 
//                 className={`option-card ${selectedOptions[category] === option ? 'selected' : ''}`}
//                 onClick={() => handleSelection(category, option)}
//               >
//                 <h3 className="option-title">{option.name}</h3>
//                 <img src={option.image} alt={option.name} className="option-image" />
//                 <p className="option-description">{option.description}</p>
//               </div>
//             ))}
//           </div>
//         );
//     };

//     const getStepContent = (step) => {
//         const dummyOptions = [
//         { name: 'Option 1', image: '/api/placeholder/400/300', description: 'Description for Option 1' },
//         { name: 'Option 2', image: '/api/placeholder/400/300', description: 'Description for Option 2' },
//         { name: 'Option 3', image: '/api/placeholder/400/300', description: 'Description for Option 3' },
//         ];

//         switch (step) {
//         case 0:
//             return renderOptions('venue', dummyOptions);
//         case 1:
//             return renderOptions('specialGuests', dummyOptions);
//         case 2:
//             return renderOptions('eventType', dummyOptions);
//         case 3:
//             return renderOptions('peopleSize', dummyOptions);
//         default:
//             return 'Unknown step';
//         }
//     };

//     return (
//         <Container w='100%' column center>

//             <div style={{ width: '100%', height: '100dvh', overflow: 'hidden'}}>

//               <div style={{columns: '10rem', maxWidth: '100%', width: '100%', overflowY: 'hidden'}}>
//                 {randomImages.map(image => (
//                   <div key={image.id}>
//                     <img width='100%' src={image.url} alt={image.description} />
//                   </div>
//                 ))}

//               </div>
//             </div>

//             <Container w='100%' gap='2rem' column center>

//                 <Stepper activeStep={activeStep} sx={{width: '90%'}} alternativeLabel>
//                     {steps.map((label) => (
//                     <Step key={label}>
//                         <StepLabel>{label}</StepLabel>
//                     </Step>
//                     ))}
//                 </Stepper>

//                 <Container column center gap='2rem' w='90%'>
//                   <h2 className="text-2xl font-bold mb-4">{t(`eventCreation.${steps[activeStep].toLowerCase()}`)}</h2>
//                   {getStepContent(activeStep)}
//                 </Container>

//                 <Container w='90%' sx={{justifyContent: 'space-between'}}>
//                   <Button
//                       onClick={handleBack}
//                       disabled={activeStep === 0}
//                   >
//                       {t('general.back')}
//                   </Button>

//                   <Button
//                       onClick={handleNext}
//                       disabled={activeStep === steps.length - 1}
//                   >
//                       {activeStep === steps.length - 1 ? t('general.finish') : t('general.next')}
//                   </Button>
//                 </Container>

//             </Container>
//         </Container>
//     );
// };


// const randomImages = [
//   { id: 1, url: 'https://picsum.photos/200/300', description: 'Image 1' },
//   { id: 2, url: 'https://picsum.photos/400/400', description: 'Image 2' },
//   { id: 3, url: 'https://picsum.photos/300/200', description: 'Image 3' },
//   { id: 4, url: 'https://picsum.photos/500/600', description: 'Image 4' },
//   { id: 5, url: 'https://picsum.photos/600/500', description: 'Image 5' },
//   { id: 6, url: 'https://picsum.photos/450/350', description: 'Image 6' },
//   { id: 7, url: 'https://picsum.photos/250/350', description: 'Image 7' },
//   { id: 8, url: 'https://picsum.photos/350/250', description: 'Image 8' },
//   { id: 9, url: 'https://picsum.photos/550/450', description: 'Image 9' },
//   { id: 10, url: 'https://picsum.photos/300/400', description: 'Image 10' },
//   { id: 11, url: 'https://picsum.photos/700/800', description: 'Image 11' },
//   { id: 12, url: 'https://picsum.photos/600/300', description: 'Image 12' },
//   { id: 13, url: 'https://picsum.photos/800/400', description: 'Image 13' },
//   { id: 14, url: 'https://picsum.photos/100/150', description: 'Image 14' },
//   { id: 15, url: 'https://picsum.photos/400/200', description: 'Image 15' },
//   { id: 16, url: 'https://picsum.photos/300/150', description: 'Image 16' },
//   { id: 17, url: 'https://picsum.photos/150/200', description: 'Image 17' },
//   { id: 18, url: 'https://picsum.photos/250/400', description: 'Image 18' },
//   { id: 19, url: 'https://picsum.photos/500/250', description: 'Image 19' },
//   { id: 20, url: 'https://picsum.photos/600/400', description: 'Image 20' },
//   { id: 1, url: 'https://picsum.photos/200/300', description: 'Image 1' },
//   { id: 2, url: 'https://picsum.photos/400/400', description: 'Image 2' },
//   { id: 3, url: 'https://picsum.photos/300/200', description: 'Image 3' },
//   { id: 4, url: 'https://picsum.photos/500/600', description: 'Image 4' },
//   { id: 5, url: 'https://picsum.photos/600/500', description: 'Image 5' },
//   { id: 6, url: 'https://picsum.photos/450/350', description: 'Image 6' },
//   { id: 7, url: 'https://picsum.photos/250/350', description: 'Image 7' },
//   { id: 8, url: 'https://picsum.photos/350/250', description: 'Image 8' },
//   { id: 9, url: 'https://picsum.photos/550/450', description: 'Image 9' },
//   { id: 10, url: 'https://picsum.photos/300/400', description: 'Image 10' },
//   { id: 11, url: 'https://picsum.photos/700/800', description: 'Image 11' },
//   { id: 12, url: 'https://picsum.photos/600/300', description: 'Image 12' },
//   { id: 13, url: 'https://picsum.photos/800/400', description: 'Image 13' },
//   { id: 14, url: 'https://picsum.photos/100/150', description: 'Image 14' },
//   { id: 15, url: 'https://picsum.photos/400/200', description: 'Image 15' },
//   { id: 16, url: 'https://picsum.photos/300/150', description: 'Image 16' },
//   { id: 17, url: 'https://picsum.photos/150/200', description: 'Image 17' },
//   { id: 18, url: 'https://picsum.photos/250/400', description: 'Image 18' },
//   { id: 19, url: 'https://picsum.photos/500/250', description: 'Image 19' },
//   { id: 20, url: 'https://picsum.photos/600/400', description: 'Image 20' },
//   { id: 1, url: 'https://picsum.photos/200/300', description: 'Image 1' },
//   { id: 2, url: 'https://picsum.photos/400/400', description: 'Image 2' },
//   { id: 3, url: 'https://picsum.photos/300/200', description: 'Image 3' },
//   { id: 4, url: 'https://picsum.photos/500/600', description: 'Image 4' },
//   { id: 5, url: 'https://picsum.photos/600/500', description: 'Image 5' },
//   { id: 6, url: 'https://picsum.photos/450/350', description: 'Image 6' },
//   { id: 7, url: 'https://picsum.photos/250/350', description: 'Image 7' },
//   { id: 8, url: 'https://picsum.photos/350/250', description: 'Image 8' },
//   { id: 9, url: 'https://picsum.photos/550/450', description: 'Image 9' },
//   { id: 10, url: 'https://picsum.photos/300/400', description: 'Image 10' },
//   { id: 11, url: 'https://picsum.photos/700/800', description: 'Image 11' },
//   { id: 12, url: 'https://picsum.photos/600/300', description: 'Image 12' },
//   { id: 13, url: 'https://picsum.photos/800/400', description: 'Image 13' },
//   { id: 14, url: 'https://picsum.photos/100/150', description: 'Image 14' },
//   { id: 15, url: 'https://picsum.photos/400/200', description: 'Image 15' },
//   { id: 16, url: 'https://picsum.photos/300/150', description: 'Image 16' },
//   { id: 17, url: 'https://picsum.photos/150/200', description: 'Image 17' },
//   { id: 18, url: 'https://picsum.photos/250/400', description: 'Image 18' },
//   { id: 19, url: 'https://picsum.photos/500/250', description: 'Image 19' },
//   { id: 20, url: 'https://picsum.photos/600/400', description: 'Image 20' },
//   { id: 1, url: 'https://picsum.photos/200/300', description: 'Image 1' },
//   { id: 2, url: 'https://picsum.photos/400/400', description: 'Image 2' },
//   { id: 3, url: 'https://picsum.photos/300/200', description: 'Image 3' },
//   { id: 4, url: 'https://picsum.photos/500/600', description: 'Image 4' },
//   { id: 5, url: 'https://picsum.photos/600/500', description: 'Image 5' },
//   { id: 6, url: 'https://picsum.photos/450/350', description: 'Image 6' },
//   { id: 7, url: 'https://picsum.photos/250/350', description: 'Image 7' },
//   { id: 8, url: 'https://picsum.photos/350/250', description: 'Image 8' },
//   { id: 9, url: 'https://picsum.photos/550/450', description: 'Image 9' },
//   { id: 10, url: 'https://picsum.photos/300/400', description: 'Image 10' },
//   { id: 11, url: 'https://picsum.photos/700/800', description: 'Image 11' },
//   { id: 12, url: 'https://picsum.photos/600/300', description: 'Image 12' },
//   { id: 13, url: 'https://picsum.photos/800/400', description: 'Image 13' },
//   { id: 14, url: 'https://picsum.photos/100/150', description: 'Image 14' },
//   { id: 15, url: 'https://picsum.photos/400/200', description: 'Image 15' },
//   { id: 16, url: 'https://picsum.photos/300/150', description: 'Image 16' },
//   { id: 17, url: 'https://picsum.photos/150/200', description: 'Image 17' },
//   { id: 18, url: 'https://picsum.photos/250/400', description: 'Image 18' },
//   { id: 19, url: 'https://picsum.photos/500/250', description: 'Image 19' },
//   { id: 20, url: 'https://picsum.photos/600/400', description: 'Image 20' },
// ];